import { materialCells, materialRenderers } from '@jsonforms/material-renderers';
import { JsonForms, JsonFormsInitStateProps } from '@jsonforms/react';
import { JsonSchema, UISchemaElement } from '@jsonforms/core';
import { Skeleton, Stack } from '@mui/material';
import MatrixRenderer from '../Renderers/MatrixRenderer'
import MatrixTester from '../Renderers/MatrixTester'

const renderers = [
  ...materialRenderers,
  { tester: MatrixTester, renderer: MatrixRenderer },
];

type FormsWrappedProps = {
  schema: Object,
  uischema: Object,
  data: any,
  setData: (data: any) => void,
  setErrors: (errors: any) => void
} & Omit<JsonFormsInitStateProps, "data" | "renderers" | "cells" | "schema" | "uischema" | "onChange">

const FormsWrapped = ({schema, uischema, data, setData, setErrors, ...other}: FormsWrappedProps): JSX.Element => {

  return (
    <>
      <JsonForms
        schema={schema as JsonSchema}
        uischema={Object.keys(uischema).length > 0 ?  uischema as UISchemaElement : undefined}
        data={data}
        renderers={renderers}
        cells={materialCells}
        onChange={({ errors, data }) => {
          // XXX: Run extra validations here if needed
          setData(data)
          setErrors(errors)
        }}
        validationMode='ValidateAndShow'
        {...other}
        />
    </>
  )
};

export const FormsWrapperSkeleton = () => {
  return (
    <Stack direction="column" spacing={1} justifyContent="space-evenly">
      <Skeleton variant="text" width="50%" height={50} />
      <Stack direction="row" spacing={1} justifyContent="space-evenly">
        <Skeleton variant="rectangular" width="50%" height={50} />
        <Skeleton variant="rectangular" width="50%" height={50} />
      </Stack>
    </Stack>
  )
}

export default FormsWrapped;
