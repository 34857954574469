import { CloudDone, DataObject, FileUpload, RemoveCircle } from "@mui/icons-material";
import { Box, Button, Grid, IconButton, Paper, Stack, Typography } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import { useDropzone } from "react-dropzone";
import { parse } from 'yaml'
import { LoadingButton } from "@mui/lab";

const Dropzone = () => {
    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        accept: {
            'application/octet-stream': [".tpr"],
            'application/json': [".json"],
            'text/yaml': [".yaml"]
        }
    });

    const [uploaded, setUploaded] = useState(false)
    const [analyzing, setAnalyzing] = useState(false)
    const navigate = useNavigate();
    let pdbData: string | null = null;

    useEffect(() => {
        if (acceptedFiles.length > 0) {
            setUploaded(true)
        } else {
            setUploaded(false)
        }
    }, [acceptedFiles])

    const clearFiles = () => {
        setUploaded(false)
        setAnalyzing(false)
    }

    const analyzeTPR = async() => {
        setAnalyzing(true)
        let data = new FormData()
        data.append('file', acceptedFiles[0])

        const getMetadata = fetch('/api/analyze', { body: data, method: 'POST' })
            .then(response => response.text())
            .then(text => text.replace('Infinity', '-1'))
            .then(text => JSON.parse(text))
        const getPdb = fetch('/api/get_pdb', { body: data, method: 'POST' })
            .then(response => response.blob())
            .then(blob => blob.text())

        let [metadata, pdbString] = await Promise.all([getMetadata, getPdb])
        pdbData = pdbString
        setAnalyzing(false)
        return metadata
    }

    const editFile = async () => {
        const extension = acceptedFiles[0].name.split('.').pop()
        let data;
    
        try {
            switch (extension) {
                case "tpr":
                    data = await analyzeTPR()
                    break;
                case "json":
                    data = JSON.parse(await acceptedFiles[0].text())
                    break;
                case "yaml":
                case "yml":
                    data = parse(await acceptedFiles[0].text())
                    break;
                default:
                    throw new Error('Invalid file type.')
                }
        } catch (error) {
            console.error(error)
            return;
        }

        const defaultData = {
            file_identification: {},
            main_information: {},
            detailed_information: {}
        }

        const metadata = {...defaultData, ...data}
        console.log(metadata)
        navigate("/editor", { state: { fileName: acceptedFiles[0].name, metadata: metadata, pdbData: pdbData } })
    }

    return (
        <Stack direction="column" spacing={5}>
            <Box>
                <Stack direction="column" spacing={1}>
                    <Typography variant="h1">Upload File</Typography>
                    {uploaded ? (
                        <Stack direction="column" spacing={1} justifyItems={"center"} sx={{ p: 2 }}>
                            <Typography variant="h3">File chosen</Typography>
                            <Grid container spacing={0.5} alignItems={"center"} sx={{ borderRadius: "54px 10px 10px 54px", boxShadow: "2px 2px #f0f8ff" }}>
                                <Grid item xs={2} sx={{ textAlign: "center", p: "0.8em", borderRadius: "50%" }}>
                                    <CloudDone sx={{ fontSize: "4.5em", color: "#007d00a1" }} />
                                </Grid>
                                <Grid item xs={7}>
                                    <Typography variant="body1">{acceptedFiles[0].name}</Typography>
                                </Grid>
                                <Grid item xs={3} justifyItems={"center"} justifyContent={"space-evenly"} direction={"row"}>
                                    <LoadingButton loading={analyzing} variant="contained" size="large" color="primary" disabled={!uploaded} onClick={() => editFile()}>Edit</LoadingButton>
                                    <IconButton color="error" size="large" onClick={() => clearFiles()}><RemoveCircle /></IconButton>
                                </Grid>
                            </Grid>
                        </Stack>
                    ) : (
                        <Paper elevation={0} sx={{ p: 5, textAlign: "center", border: "2.5px dashed", "&:hover": { borderColor: "#F47D20", cursor: "pointer" } }} variant="outlined" >
                            <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                <FileUpload sx={{ fontSize: "7em", color: "#F47D20" }} />
                                <Typography variant="h3">Upload files here</Typography>
                                <Typography variant="subtitle1"><em>(Only [*.tpr *.json *.yaml] are accepted)</em></Typography>
                            </div>
                        </Paper>
                    )}
                </Stack>
            </Box>
        </Stack>
    );
}


export default Dropzone;
