import { Box, Divider, Stack, Typography, Link } from "@mui/material"

const Footer = () => {

    return (
        <Box sx={{ mt: 5, mb: 3 }}>
            <Divider sx={{ mt: 2, mb: 2 }} />
            <Stack direction="column" justifyContent="space-between" alignItems="center">
                <Link href="/" variant="h3" underline="none" sx={{mb: 1, fontWeight: "bold", color: "inherit"}}>Gromacs <span style={{color: "rgb(244, 125, 32)"}}>Meta</span>Dump</Link>
                <Typography variant="body2">2024</Typography>
                <Typography variant="body2" textAlign="center">Made with ❤️ by <Link href="https://disa.fi.muni.cz/complex-data-analysis">Intelligent Systems for Complex Data Research Group</Link></Typography>
            </Stack>
        </Box>
    )
}

export default Footer;
