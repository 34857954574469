import { GitHub, Mail, Help } from "@mui/icons-material";
import { Box, Link, Divider, IconButton, Stack, Tooltip, Typography } from "@mui/material"

const Header = () => {

    return (
        <Box sx={{mt: 3, mb: 3}}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Link href="/" underline="none" sx={{color: "inherit"}}>
                    <Typography variant="h1">Gromacs <span style={{color: "rgb(244, 125, 32)"}}>Meta</span>Dump</Typography>
                    <Typography variant="h3" sx={{mt: "0.5em"}}>A tool to describe molecular dynamics simulations with powerfull metadata</Typography>
                </Link>
                <Box>
                    { /* TODO: add actual links */}
                    <Tooltip title="Github">
                        <IconButton href="https://github.com/sb-ncbr" size="small" target="_blank">
                            <GitHub sx={{fontSize: "2em"}}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Contact us">
                        <IconButton href="mailto:mail@example.com" size="small" target="_blank">
                            <Mail sx={{fontSize: "2em"}}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Manual">
                        <IconButton href="https://matias.me/nsfw/" size="small" target="_blank">
                            <Help sx={{fontSize: "2em"}}/>
                        </IconButton>
                    </Tooltip>
                </Box>
            </Stack>
            <Divider sx={{mt: "1em"}}/>
        </Box>
    )
}

export default Header;