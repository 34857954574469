import { Box, Stack } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Header from './Header';
import Footer from './Footer';

const Layout = () => {

  const navigate = useNavigate()

  return (
    <Box bgcolor={"background.default"} color={"text.primary"}>
      <Stack direction="row" justifyContent="center">
        <Box width={"60%"}>
          <Header />
          <Outlet />
          <Footer />
        </Box>
      </Stack>
    </Box>
  );
}

export default Layout;
