import { Stack, Grid, Typography } from "@mui/material"
import Dropzone from "../Components/Dropzone"
import Example from "../Components/Example"

const Frontpage = () => {

    return (
        <Stack direction="column" spacing={5}>
            <Typography variant="h1">About</Typography>
            <Typography>
                This tool is designed to help you analyze and edit the metadata of a GMX file.
                You can upload a TPR file to analyze its metadata and download the metadata in JSON or YAML format.
            </Typography>
            <Dropzone ></Dropzone>
            <Typography variant="h1" mb={5}>Examples</Typography>
            <Grid container justifyContent="center">
                {/**
                 * TODO:
                 *  - add normal example files
                 */}
                <Example name="Protein 1" json="NPT.json" img="example.jpg" />
                <Example name="Protein 2" json="NPT.json" img="example.jpg" />
                <Example name="Protein 3" json="NPT.json" img="example.jpg" />
            </Grid>
        </Stack>
    )
}

export default Frontpage
