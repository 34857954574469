import { Button, Stack, Typography } from "@mui/material";
import { useNavigate } from 'react-router-dom';
import ProteinViewer from "./ProteinViewer";

interface ExampleProps {
    name: string
    json: string
    img: string
}

const Example = (props: ExampleProps) => {
    const { name, json, img } = props
    const navigate = useNavigate()

    const loadExample = async (fileName: string) => {
        fetch(`/examples/${fileName}`)
            .then(response => response.text())
            .then(data => JSON.parse(data))
            .then(data => {
                console.log(data)
                navigate("/editor", { state: { metadata: data, fileName: fileName } })
            })
    }

    return (
        <Stack spacing={2} justifyContent="center" alignItems="center" margin={2}>
            <Typography variant="h3" fontWeight="bold">{name}</Typography>
            <img src={"/examples/" + img} height={250} width={250} />
            <Button size="large" variant="contained" color="primary" onClick={() => loadExample(json)}>View metadata</Button>
        </Stack>
    )
}

export default Example
